import { GetStaticProps, NextPage } from "next";
import { useModalContext } from "../components/utils/Modal";
import { useState } from "react";
import { useConfirm } from "@wk93/socommerce-sdk";
import { useRouter } from "next/dist/client/router";
import Link from "next/link";
import HomeCategoryBanner from "../components/gnt/home/HomeCategoryBanner";
import BannerContent from "../components/gnt/home/BannerContent";
import Instagram from "../components/layout/homepage/Instagram";
import Title from "../components/layout/utils/Title";
import Image from "next/legacy/image";
import { isWithinInterval } from "date-fns";
import { Banner, BannerElement, getBanners } from "../utils/getBanners";
import Movie from "../components/gnt/home/Movie";

interface Props {
  banners: Banner[];
}

const Home: NextPage<Props> = ({ banners }) => {
  const [email, setEmail] = useState("");
  const { showModal } = useModalContext();
  const router = useRouter();

  const childrenModal = (
    <button
      onClick={() => {
        showModal({ show: false, title: "", description: "" });
        if (email.length > 1) {
          setEmail("");
        }
      }}
      type="button"
      className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-500 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-600 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
    >
      Zamknij
    </button>
  );

  const { isLoading } = useConfirm(async (action, email) => {
    if (action === "SUCCESS_SUBSCRIBE" && email) {
      setEmail(email);
    }

    const title = action === "ERROR"
      ? "Wystąpił błąd"
      : "Dziękujemy za potwierdzenie";
    const type = action === "ERROR" ? "error" : "success";
    let description;

    if (action === "ERROR") {
      description =
        "Najprawdopodobniej już potwierdzieś operację lub link jest niepoprawny.";
    } else {
      description = action === "SUCCESS_SUBSCRIBE"
        ? "Zostałeś zapisany do naszego newslettera"
        : action === "SUCCESS_REGISTER"
        ? "Twoje konto zostało aktywowane"
        : action === "SUCCESS_NOTIFY"
        ? "Powiadomimy Cię, kiedy produkt znowu pojwi się w naszej ofercie"
        : "Twój adres email został usunięty z naszej bazy newsletterowej";
    }

    /*
    if (action === "SUCCESS_SUBSCRIBE") {
      await sendMailToSm(email, "NEWSLETTER_POTW");
    }
    if (action === "SUCCESS_REGISTER") {
      await sendMailToSm(email, "REJESTRACJA_POTW");
    }
    */

    showModal({
      title,
      type,
      description,
      show: true,
      children: childrenModal,
    });

    router.replace("/", undefined, { shallow: true });
  });

  const mainBanner: BannerElement[] =
    (banners.filter((banner) => banner.id === "63b16ba15d6af15c1f4b146d")[0] ||
      { elements: [] }).elements.filter((element) => element.lang === "pl")
      .filter((element) => {
        if (element.start && element.end) {
          return isWithinInterval(new Date(), {
            start: new Date(element.start),
            end: new Date(element.end),
          });
        }
        return true;
      });

  const catBanners: BannerElement[] =
    (banners.filter((banner) => banner.id === "63b16bb05d6af15c1f4b165b")[0] ||
      { elements: [] }).elements.filter((element) => element.lang === "pl")
      .filter((element) => {
        if (element.start && element.end) {
          return isWithinInterval(new Date(), {
            start: new Date(element.start),
            end: new Date(element.end),
          });
        }
        return true;
      });

  return (
    <>
      <div className="px-0 pb-4 lg:px-8 lg:pb-8 bg-black">
        <div className="grid grid-cols-1 gap-y-4 lg:grid-cols-2 lg:gap-8">
          <div className="lg:col-span-2">
            <Link href="/black-capsule">
              <Movie src="/gnt/241007/video.mp4" />
            </Link>
            {
              /*
                 mainBanner.length > 0
              ? (
                (
                  <Link href={mainBanner[0].href}>
                    <div className="relative">
                      <div className="hidden lg:block">
                        <Image
                          width={mainBanner[0].images[0].width}
                          height={mainBanner[0].images[0].height}
                          alt={mainBanner[0].alt}
                          layout="responsive"
                          src={mainBanner[0].images[0].src}
                        />
                      </div>
                      <div className="block lg:hidden">
                        <Image
                          width={mainBanner[0].images[1].width}
                          height={mainBanner[0].images[1].height}
                          alt={mainBanner[0].alt}
                          layout="responsive"
                          src={mainBanner[0].images[1].src}
                        />
                      </div>
                    </div>
                  </Link>
                )
              )
              : null

            */
            }
          </div>

          {catBanners.map((item, index) => (
            <HomeCategoryBanner
              key={item.href}
              href={item.href}
              src={item.images[0].src}
              width={item.images[0].width}
              height={item.images[0].height}
              quality={100}
            >
              <div className="absolute bottom-0 left-0 right-0">
                <BannerContent header={item.alt} button="Sprawdź" />
              </div>
            </HomeCategoryBanner>
          ))}
        </div>
        <div className="px-0 pt-12">
          <Title size={0}>@guns_and_tuxedos</Title>
          <Instagram
            images={[
              "/gnt/ig/1.jpg",
              "/gnt/ig/2.jpg",
              "/gnt/ig/3.jpg",
              "/gnt/ig/4.jpg",
              "/gnt/ig/5.jpg",
              "/gnt/ig/6.jpg",
              "/gnt/ig/7.jpg",
              "/gnt/ig/8.jpg",
              "/gnt/ig/9.jpg",
            ]}
          />
        </div>
      </div>
    </>
  );
};

export const getStaticProps: GetStaticProps = async ({ params }) => {
  const banners = await getBanners();
  return {
    props: {
      banners: banners,
    },
    revalidate: 900,
  };
};

export default Home;
