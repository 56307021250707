import React from "react";
import { classNames } from "../../utils";
import Button from "../../utils/Button";

interface Props {
  header?: string;
  button?: string;
  black?: boolean;
}

const BannerContent: React.FC<Props> = ({ header, button, black = false }) => {
  return (
    <div
      className={classNames(
        "flex items-center justify-center p-12 py-8 lg:py-20 text-center",
        black ? "text-green-600" : "text-white",
      )}
    >
      <div>
        {header
          ? <p className="text-xl lg:text-4xl mb-2 uppercase">{header}</p>
          : null}

        {button
          ? black
            ? (
              <button
                className={`inline-flex items-center justify-center transition ease-in-out duration-150 ${"bg-black text-green-600 hover:bg-black hover:text-green-500"} border border-transparent py-2 px-3 text-sm`}
              >
                <span className="block uppercase px-6">{button}</span>
              </button>
            )
            : (
              <Button inverse size="small">
                <span className="uppercase px-6">{button}</span>
              </Button>
            )
          : null}
      </div>
    </div>
  );
};

export default BannerContent;
