import React from 'react';
import Image from "next/legacy/image";

interface Props {
  images: string[];
}

const Instagram: React.FC<Props> = ({ images }) => (
  <div className='mx-auto'>
    <div className='grid items-center grid-cols-1 gap-y-4 gap-x-8 lg:grid-cols-3'>
      <div className='aspect-w-1 aspect-h-1  bg-gray-100 overflow-hidden'>
        <Image
          alt='Guns&Tuxedos - Instagram'
          src={images[0]}
          layout='fill'
          objectPosition='center'
          objectFit='cover'
        />
      </div>
      <div className='grid grid-cols-2 gap-4 sm:gap-6 lg:gap-8'>
        <div className='aspect-w-1 aspect-h-1  bg-gray-100 overflow-hidden'>
          <Image
            alt='Guns&Tuxedos - Instagram'
            src={images[1]}
            layout='fill'
            objectPosition='center'
            objectFit='cover'
          />
        </div>
        <div className='aspect-w-1 aspect-h-1  bg-gray-100 overflow-hidden'>
          <Image
            alt='Guns&Tuxedos - Instagram'
            src={images[2]}
            layout='fill'
            objectPosition='center'
            objectFit='cover'
          />
        </div>
        <div className='aspect-w-1 aspect-h-1  bg-gray-100 overflow-hidden'>
          <Image
            alt='Guns&Tuxedos - Instagram'
            src={images[3]}
            layout='fill'
            objectPosition='center'
            objectFit='cover'
          />
        </div>
        <div className='aspect-w-1 aspect-h-1  bg-gray-100 overflow-hidden'>
          <Image
            alt='Guns&Tuxedos - Instagram'
            src={images[4]}
            layout='fill'
            objectPosition='center'
            objectFit='cover'
          />
        </div>
      </div>
      <div className='grid grid-cols-2 gap-4 sm:gap-6 lg:gap-8'>
        <div className='aspect-w-1 aspect-h-1  bg-gray-100 overflow-hidden'>
          <Image
            alt='Guns&Tuxedos - Instagram'
            src={images[5]}
            layout='fill'
            objectPosition='center'
            objectFit='cover'
          />
        </div>
        <div className='aspect-w-1 aspect-h-1  bg-gray-100 overflow-hidden'>
          <Image
            alt='Guns&Tuxedos - Instagram'
            src={images[6]}
            layout='fill'
            objectPosition='center'
            objectFit='cover'
          />
        </div>
        <div className='aspect-w-1 aspect-h-1  bg-gray-100 overflow-hidden'>
          <Image
            alt='Guns&Tuxedos - Instagram'
            src={images[7]}
            layout='fill'
            objectPosition='center'
            objectFit='cover'
          />
        </div>
        <div className='aspect-w-1 aspect-h-1  bg-gray-100 overflow-hidden'>
          <Image
            alt='Guns&Tuxedos - Instagram'
            src={images[8]}
            layout='fill'
            objectPosition='center'
            objectFit='cover'
          />
        </div>
      </div>
    </div>
  </div>
);

export default Instagram;
