import React, { useEffect, useRef, useState } from "react";
import { classNames } from "../../utils";
import useFloatingContent from "./useFloatingContent";

interface Props {
  src: string;
}
const Movie: React.FC<Props> = ({ children, src }) => {
  //  const { contentRef, boxRef, margin } = useFloatingContent();

  return (
    <div className="relative" 
    //  ref={boxRef}
    >
      <video
        autoPlay={true}
        controls={false}
        muted={true}
        loop={true}
        playsInline={true}
        className="object-cover object-center h-screen lg:h-auto"
      >
        <source src={src} type="video/mp4" />
      </video>
      {
        /*
      <div className='absolute inset-0 bg-black bg-opacity-20 flex items-center justify-center'></div>
      <div
        ref={contentRef}
        className={classNames(
          margin > 0 ? 'sticky' : 'absolute',
          'bottom-0 left-0 right-0'
        )}
        style={{ marginTop: `-${margin}px` }}
      >
        <img
          src='/gnt/sword-white.png'
          className='w-16 h-16 block mx-auto -mb-8 lg:-mb-16'
        />
        {children}
      </div>
      */
      }
    </div>
  );
};

export default Movie;
