import React from "react";
import Link from "next/link";
import ImageBlock from "../ImageBlock";
interface Props {
  href: string;
  src: string;
  width: number;
  height: number;
  quality?: number;
}

const HomeCategoryBanner: React.FC<Props> = ({
  href,
  src,
  width,
  height,
  quality,
  children,
}) => (
  (<Link href={href}>

    <div className="group relative cursor-pointer">
      <ImageBlock
        src={src}
        width={width}
        height={height}
        quality={quality}
        sizes="(max-width: 1024px) 100vw,
                  50vw"
      />
      <div className="absolute inset-0 bg-black bg-opacity-20"></div>
              {children}
    </div>

  </Link>)
);

export default HomeCategoryBanner;
